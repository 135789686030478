<template>
  <div>
  <div class="container">
      <el-row>
        <!-- logo -->
        <el-col :span="12">
          <div class="wingSty">
            <img src="@/assets/logo.png" alt="">
          </div>
        </el-col>
        <div style="margin-top: 10px;">
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <!-- <el-menu router :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
                background-color="white" text-color="black" active-text-color="red"> -->
                <el-menu router :default-active="activeIndex"
                active-text-color='#ffd04b'
                text-color="#000000"
                background-color="#fff"
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect">
                <el-menu-item index="/">首页</el-menu-item>
                <el-submenu>
                  <template slot="title">服务产品</template>
                  <el-menu-item index="flexibleEmployment" class="childNav">解决方案</el-menu-item>
                  <el-menu-item index="collaborationProcess" class="childNav">合作流程</el-menu-item>
                  <!-- <el-menu-item index="taxspirit">个体工商户</el-menu-item> -->
                </el-submenu>
                <el-menu-item index="/" >
                  <!--  @click="scrollToSection" -->
                  <a href="#sectionId" style="text-decoration: none;">最新动态</a>
                </el-menu-item>
                  <!-- <el-menu-item index="riskControl">智能风控</el-menu-item> -->
                <!-- <el-menu-item index="technologicalEmpowerment">技术赋能</el-menu-item> -->
                <el-menu-item index="companyProfile" class="childNav">关于我们</el-menu-item>
                <!-- <el-submenu index="about">
                  <template slot="title">关于我们</template>
                  <el-menu-item index="companyProfile" class="childNav">公司介绍</el-menu-item>
                  <el-menu-item index="contactUs" class="childNav">联系我们</el-menu-item>
                </el-submenu> -->
              </el-menu>
            </div>
          </el-col>
        </div>
      </el-row>
    </div>
  </div>
</template>
<script>

import VueScrollTo from 'vue-scrollto'
export default {
  name: 'template',
  data () {
    return {
      activeIndex: '1'
    }
  },
  components: {
  },
  watch: {},
  methods: {
    scrollToSection () {
      VueScrollTo.scrollTo(`#${'sectionId'}`, 800)
    },

    navClick () {

    },
    handleSelect (key, keyPath) {
      // console.log(key, keyPath)
    }
  }
}
</script>
<style scoped lang="less">
.wingSty{
  text-align: left;
  margin-left: 150px;
}

.childNav{
  text-align: center;
  font-size: 18px;
  // max-width: 100px;
}
.el-menu--horizontal>.el-menu-item {
  font-size: 20px;
}
::v-deep .el-submenu__icon-arrow{
  font-size: 25px !important;
}
::v-deep .el-submenu__title{
  font-size: 20px !important;
}
.el-menu--collapse .el-menu .el-submenu, .el-menu--popup{
  min-width: 120px!important;
  font-size: 40px !important;
}
</style>
