<template>
<div>
  <div class="footStyle">
    <div class="conentStyle">
      <!-- 图标 -->
      <div style="margin-left: 33px;margin-top: 60px;flex: 1;text-align:center;">
        <img src="@/assets/白字LOGO02.png" alt="" style="width: 60%;">
      </div>
        <!-- 产品服务-->
        <div style="margin-top: 70px;flex: 1;text-align: right;padding-left: 50px;">
          <el-row :gutter="20">
            <el-col :span="8" style="color: #fff;font-size: 18px;"><div class="grid-content bg-purple">服务产品</div></el-col>
            <!-- <el-col :span="8" style="color: #fff;"><div class="grid-content bg-purple-light">行业动态</div></el-col> -->
            <el-col :span="8" style="color: #fff;font-size: 18px;"><div class="grid-content bg-purple">公司文化</div></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8" class="footDetail"><div class="grid-content bg-purple">
              <el-button type="text" @click="handleSolution" >解决方案</el-button>
              <!-- <el-link type="primary" >解决方案</el-link> -->
              <!-- <router-link to="/flexibleEmployment">解决方案</router-link> -->
              <!-- <a href="#/companyProfile"  style="text-decoration: none;color: #5a9cf8;font-size: 16px;font-weight: 600;">解决方案</a> -->
            </div></el-col>
            <el-col :span="8" class="footDetail"><div class="grid-content bg-purple">
              <el-button type="text" @click="handleUs">关于我们</el-button>
            </div>
          </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8" class="footDetail"><div class="grid-content bg-purple">
              <el-button type="text" @click="handleProcess">合作流程</el-button>

              <!-- <a href="@/views/collaborationProcess/collaborationProcess.vue"  style="text-decoration: none;color: #5a9cf8;font-size: 16px;font-weight: 600;">合作流程</a> -->
            </div></el-col>
            <!-- <el-col :span="8" class="footDetail"><div class="grid-content bg-purple-light">技术赋能</div></el-col> -->
            <!-- <el-col :span="8" class="footDetail"><div class="grid-content bg-purple">加入我们</div></el-col> -->
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8" style="color: #151b1e;"><div class="grid-content bg-purple">123</div></el-col>
            <el-col :span="8" style="color: #151b1e;"><div class="grid-content bg-purple-light">123</div></el-col>
            <!-- <el-col :span="8" class="footDetail"><div class="grid-content bg-purple">联系我们</div></el-col> -->
          </el-row>
        </div>
        <!-- 二维码 -->
        <div style="margin-top: 5%;text-align: right;">
          <img src="@/assets/一百工夫公众号.jpg" alt="" style="width: 35%;margin-right: 150px;">
          <p style="color: #fff;font-size: 10px;text-align: center;">一百工服微信公众号</p>
          <!-- <p style="color: #fff;font-size: 14px;"></p> -->
        </div>
        <!-- <div style="flex: 1;margin-top: 50px;">
          <img src="@/assets/gsjl.jpeg" alt="" style="width: 80%;">
          <p style="color: #fff;font-size: 14px;">云科精灵News</p>
        </div> -->
    </div>
      <div style="display: flex;">
        <!-- 地址信息 -->
        <div style="flex: 1;">
          <el-row style="text-align:center;font-size: 20px;">
              <el-col :span="24"><div class="grid-content bg-purple-dark">
                <span class="el-icon-phone" style="color: rgb(237, 228, 228);line-height: 40px;margin-right: 10px;"></span><span style="color: rgb(237, 228, 228);">客服热线: 15858162055</span>
              </div></el-col>
          </el-row>
          <el-row style="text-align:center;font-size: 20px;">
            <el-col :span="24"><div class="grid-content bg-purple-dark">
              <span class="el-icon-s-cooperation" style="color: rgb(237, 228, 228);line-height: 40px;margin-right: 10px"></span><span style="color: rgb(237, 228, 228);">商务合作:  yibaishiqqian@qq.com</span>
            </div></el-col>
          </el-row>
          <el-row style="text-align:center;font-size: 20px;">
            <el-col :span="24"><div class="grid-content bg-purple-dark">
              <span class="el-icon-location" style="color: rgb(237, 228, 228);line-height: 40px;margin-right: 10px"></span><span style="color: rgb(237, 228, 228);">联系地址: 九亭金地广场2号楼609室 </span>
            </div></el-col>
          </el-row>
        </div>
      <!-- 在线留言 -->
      <div style="flex: 1;margin-top: 10px;">
        <el-form>
        <el-form-item label="在线留言" style="width: 50%;text-align: right;">
          <el-input type="textarea" v-model="desc" :rows="3" placeholder="请输入您的意见反馈">1234567</el-input>
        </el-form-item>
        <el-button type="primary" size="mini" style="margin-left: 50px;">提交</el-button>
      </el-form>
      </div>
      </div>

      <div style="border: 0.2px solid #4e5255;margin-top: 30px;margin-bottom: 30px;"></div>
      <!-- <el-divider></el-divider> -->
       <!-- 页尾 -->
      <div>
        <span :span="8" style="margin-right: 20px;">上海一百十千信息科技有限公司 版权所有 沪ICP备2023023422号-1</span>
        <!-- <span :span="8" style="margin-right: 40px;">上海一百十千信息科技有限公司 版权所有 <img src="@/assets/police.jpeg" alt="" style="margin-left: 30px;">沪ICP备2023023422号-1</span> -->
      </div>
      <!-- <p class="footDetail1">增值电信业务经营许可证 浙B2-20180051</p> -->
  </div>
</div>
</template>
<script>
// import VueScrollTo from 'vue-scrollto'
export default {
  name: 'template',
  data () {
    return {
      desc: ''
    }
  },
  components: {
  },
  mounted () {
    // console.log(this.$router.history.current.fullPath, '路由信息')
  },
  methods: {
    handleSolution () {
      this.$router.push('flexibleEmployment')

      // if (this.$router.history.current.fullPath === '/flexibleEmployment') {
      //   return false
      // }
    },
    handleProcess () {
      this.$router.push('collaborationProcess')
    },
    handleUs () {
      this.$router.push('companyProfile')
    }
    // scrollToSection () {
    //   VueScrollTo.scrollTo(`#${'sectionId'}`, 800)
    // }
  }
}
</script>
<style scoped lang="less">
.footStyle{
  width: 100%;
  min-width: 1200px;
  height: 100%;
  background-color: #151b1e;
  padding-bottom: 20px;
}
.footDetail{
  color: #938b8b;
  font-size: 12px;
  line-height: 40px;
}
.footDetail1{
  color: #938b8b;
  font-size: 12px;
  line-height: 10px;
  padding: 10px;
}
.conentStyle{
  display: flex;
}

</style>
