import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/flexibleEmployment',
    name: 'flexibleEmployment',
    component: () => import('../views/flexibleEmployment/flexibleEmployment.vue')
  },
  {
    path: '/taxspirit',
    name: 'taxspirit',
    component: () => import('../views/taxspirit/taxspirit.vue')
  },
  {
    path: '/collaborationProcess',
    name: 'collaborationProcess',
    component: () => import('../views/collaborationProcess/collaborationProcess.vue')
  },
  {
    path: '/riskControl',
    name: 'riskControl',
    component: () => import('../views/riskControl/riskControl.vue')
  },
  {
    path: '/technologicalEmpowerment',
    name: 'technologicalEmpowerment',
    component: () => import('../views/technologicalEmpowerment/technologicalEmpowerment.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/contactUs/contactUs.vue')
  },
  {
    path: '/companyProfile',
    name: 'companyProfile',
    component: () => import('../views/companyProfile/companyProfile.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  }

})

export default router
