<template>
  <div id="app">
    <!-- 导航栏 -->
    <HeadView></HeadView>
    <!-- 轮播图 -->
    <div>
      <el-carousel height="40em">
          <el-carousel-item >
            <img src="@/assets/banner01.jpg" alt="" style="width: 100%;">
          </el-carousel-item>
          <el-carousel-item >
            <img src="@/assets/banner02.jpg" alt="" style="width: 100%;">
          </el-carousel-item>
          <el-carousel-item >
            <img src="@/assets/banner03.jpg" alt="" style="width: 100%;">
          </el-carousel-item>
        </el-carousel>
      </div>
    <!-- 一百工服-->
    <div >
      <el-container>
        <el-header style="height: 180px;font-size: 40px;font-weight: 600;;color: #262626;background-color: transparent;" class="animate__animated animate__fadeInDown">一百工服</el-header>
        <el-container>
          <el-aside style="width: 45%;text-align: center;height: 40%;margin-left: 30px;">
            <img src="@/assets/电脑展示.png" alt="" style="width: 100%;">
          </el-aside>
          <el-main style="padding-top: 0;">
            <h3 style="font-weight: 400px;">一百工服综合服务平台</h3>
            <h5 style="color: #3d3a3a;font-weight: normal;margin-right: 15%;">基于AI、大数据、人工智能等底层技术，自研的数字化系统；可进行品牌定制，运营支持，税源地资源共享；为企业和自由职业者等提供一站式便捷服务。</h5>
            <el-button type="primary" round><a href="https://admin.yibaitec.com/" style="text-decoration: none;color: white;" target="_blank">立即前往</a> ></el-button>
          </el-main>
        </el-container>
      </el-container>
    </div>
    <!-- 合作流程 -->
    <!-- <div style="padding:0 100px;">
      <el-container>
        <el-header style="height: 180px;font-size: 40px;font-weight: 600;;color: #262626;background-color: transparent;" class="animate__animated animate__fadeInDown">合作流程</el-header>
        <el-container>
          <el-aside style="width: 45%;height:40%;">
            <img src="@/assets/WechatIMG496.jpeg" alt="" style="width: 100%;">
          </el-aside>
          <el-main>
            <h3>灵活用工智能结算和个税申报系统</h3>
            <h5 style="color: #3d3a3a;font-weight: normal;">
              灵活用工智能结算和个税申报系统结算云是在共享经济、灵活用工场景逐渐普及的市场环境下，51个税推出的佣金结算和个税申报系统，通过更加智能和便捷的个税合规解决方案，为企业提供酬劳发放、个税代征、薪税合规等服务。</h5>
            <el-button type="danger" @click="goMore">了解更多 ></el-button>
          </el-main>
        </el-container>
      </el-container>
    </div> -->
    <!-- 核心优势 -->
    <div style="padding:0 100px;">
      <el-container>
        <el-header style="height: 180px;font-size: 40px;font-weight: 600;;color: #262626;background-color: transparent;" class="animate__animated animate__fadeInDown">核心优势</el-header>
        <el-container>
            <div class="advantageSty">
              <img style="width: 50px;height: 50px;margin-top: 30px;" src="@/assets/icon1@2x.png" alt="">
              <h2>灵活用工</h2>
              <p style="font-size: 14px;padding: 0 30px;color: #252323;line-height: 18px;text-align: left;">转变传统用工关系，采用具有高度灵活性的用工模式，用工因需而变，满足快速变化的业务需求</p>
            </div>
            <div class="advantageSty1">
              <img style="width: 50px;height: 50px;margin-top: 30px;" src="@/assets/icon2@2x.png" alt="">
              <h2>报酬代发</h2>
              <p style="font-size: 14px;padding: 0 30px;color: #252323;line-height: 18px;text-align: left;">报税发放+个税申报一体化处理，资深专家提供专业服务标准化服务流程，便捷高效，信息保密、资金安全</p>
            </div>
            <div class="advantageSty2">
              <img style="width: 50px;height: 50px;margin-top: 30px;" src="@/assets/icon3@2x.png" alt="">
              <h2>降本增效</h2>
              <p style="font-size: 14px;padding: 0 30px;color: #252323;line-height: 18px;text-align: left;">降低成本，减少企业压力，助力企业更好的发展</p>
            </div>
            <div class="advantageSty3">
              <img style="width: 50px;height: 50px;margin-top: 30px;" src="@/assets/icon4@2x.png" alt="">
              <h2>园区招商</h2>
              <p style="font-size: 14px;padding: 0 30px;color: #252323;line-height: 18px;text-align: left;">通过线上大数据平台与线下服务团队联动模式，实现项目的精准导入及落地</p>
            </div>
        </el-container>
      </el-container>
    </div>
    <!-- 灵活用工场景 -->
    <div >
      <el-container class="footContainer">
        <el-header style="height: 180px;font-size: 40px;font-weight: 600;;color: #262626;background-color: transparent;" class="animate__animated animate__fadeInDown">灵活用工场景</el-header>
          <div class="left-mask"></div>
        <div class="scroll_box">
            <ul>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/01电商行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/02教育行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/03零售行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/04支付行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/05出行行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/06家政物业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/01电商行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/02教育行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/03零售行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/04支付行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/05出行行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/06家政物业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/01电商行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/02教育行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/03零售行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/04支付行业.png">
                </li>
              </el-card>

                <!-- 用于衔接造成视觉误差的的3张图 -->
                <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/01电商行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/02教育行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/03零售行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/04支付行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/05出行行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/06家政物业.png">
                </li>
              </el-card>
            </ul>
            <ul>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/07游戏行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/08物流行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/09餐饮服务.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/10传媒行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/11咨询培训.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/12小说平台.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/07游戏行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/08物流行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/09餐饮服务.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/10传媒行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/11咨询培训.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/12小说平台.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/07游戏行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/08物流行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/09餐饮服务.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/10传媒行业.png">
                </li>
              </el-card>

                <!-- 用于衔接造成视觉误差的的3张图 -->
                <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/07游戏行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/08物流行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/09餐饮服务.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/10传媒行业.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/11咨询培训.png">
                </li>
              </el-card>
              <el-card class="box-card cardSty">
                <li class="topSty">
                  <img src="@/assets/12小说平台.png">
                </li>
              </el-card>
            </ul>
        </div>
        <div class="right-mask"></div>
      </el-container>
    </div>
     <!-- 最新动态 -->
     <div style="padding:0 100px;">
      <el-container>
        <el-header id="sectionId" style="height: 180px;font-size: 40px;font-weight: 600;;color: #262626;background-color: transparent;" class="animate__animated animate__fadeInDown">
          <!-- <a id="miao"  id="sectionId"></a> -->
          最新动态</el-header>
        <!-- <el-container>
          <el-aside style="width: 100%;">
            <el-carousel indicator-position="outside">
            <el-carousel-item v-for="item in 3" :key="item">
              <el-row >
                <el-col :span="8" style="background-color: #fff;">
                  <img src="@/assets/9b2a8c9ac6414c3ca26b8dca30212a6b.jpeg" alt="" style="width: 90%;">
                  <p style="font-size: 14px;font-weight: 400;">一百云科荣获2021中国SaaS智能财税优秀企业奖</p>
                </el-col>
                <el-col :span="8" style="background-color: #fff;">
                  <img src="@/assets/a50402caff48470cbb3e96d917766e5e.jpeg" alt="" style="width: 90%;">
                  <p style="font-size: 14px;font-weight: 400;">一百云科荣获2021中国SaaS智能财税优秀企业奖</p>
                </el-col>
                <el-col :span="8" style="background-color: #fff;">
                  <img src="@/assets/c80c1d9e18a54ed1b5f7fc8a9234018d.jpeg" alt="" style="width: 90%;">
                  <p style="font-size: 14px;font-weight: 400;">一百云科荣获2021中国SaaS智能财税优秀企业奖</p>
                </el-col>
              </el-row>
            </el-carousel-item>
          </el-carousel>
          </el-aside>
        </el-container> -->
      <el-carousel :interval="4000" type="card" height="530px" class="news">
        <!-- <el-carousel-item v-for="item in 6" :key="item">
          <h3 class="medium">{{ item }}</h3>
        </el-carousel-item> -->
       <el-carousel-item >
          <el-card>
            <img src="@/assets/new01.png" alt="" style="height: auto;width: 100%;height: 300px;">
            <h1>灵活用工有望推动传统组织形态向新业态转型</h1>
            <p style="padding: 0 20px;text-align: left;text-indent:30px">ClearlyRated⼀项研究显示，78%的企业使用过远程自由工作者。数字灵工是区别于传统固定用工的一种弹性用工模式，本质上是灵活用工的高级形态，属于零工经济范畴。</p>
            </el-card>
          </el-carousel-item>
          <el-carousel-item >
          <el-card>
            <img src="@/assets/news02.jpeg" alt="" style="height: 300px;width: 100%;height: 300px;">
            <h1>灵活用工成两会热点</h1>
            <p style="padding: 0 20px;text-align: left;text-indent:30px">今年4月7日，国家印发《关于推进“上云用数赋智”行动》中提到，“大力发展共享经济、数字贸易、零工经济，支持新零售、互联网医疗、线上教育、一站式出行、共享员工、远程办公、“宅经济”等新业态。鼓励发展共享员工等灵活就业新模式，充分发挥数字经济蓄水池作用。”</p>
            </el-card>
          </el-carousel-item>
          <el-carousel-item >
          <el-card>
            <img src="@/assets/news03.jpeg" alt="" style="height: auto;width: 100%;height: 300px;">
            <h1>数字灵工：未来工作的流行趋势</h1>
            <p style="padding: 0 20px;text-align: left;text-indent:30px">ClearlyRated⼀项研究显示，78%的企业使用过远程自由工作者，47%的企业计划在未来两年使用更多远程自由工作者。数字灵工是区别于传统固定用工的一种弹性用工模式，本质上是灵活用工的高级形态，属于零工经济范畴。</p>
            </el-card>
          </el-carousel-item>
          <el-carousel-item >
          <el-card>
            <img src="@/assets/news_02.jpeg" alt="" style="height: auto;width: 100%;height: 300px;">
            <h1>优质的灵活用工服务才能带动行业升级</h1>
            <p style="padding: 0 20px;text-align: left;text-indent:30px">随着数字技术和共享经济的发展，服务行业、新零售行业、互联网行业都开始趋向于多元化的用工方式。灵活用工成为炙手可热的话题，很多行业希望借助灵活用工平台实现转型，以适应时代发展的需要。</p>
            </el-card>
          </el-carousel-item>
          <el-carousel-item >
          <el-card>
            <img src="@/assets/news05.jpeg" alt="" style="height: auto;width: 100%;height: 300px;">
            <h1>企业薪资代发：解放企业财务，降低人力成本</h1>
            <p style="padding: 0 20px;text-align: left;text-indent:30px">在企业管理中，薪资代发作为一项重要的服务，不仅可以帮助企业简化财务流程，还能够降低人力成本。薪资代发是将企业的薪酬支付工作交由专业机构代为处理的使用方式。</p>
            </el-card>
          </el-carousel-item>
      </el-carousel>
      </el-container>
    </div>
    <!-- 预约服务 -->
    <div style="position: fixed;right: 10px;width: 70px;height: 200px;background-color: #333333;top: 45%;z-index: 999;text-align: center;">
     <!-- 预约服务 -->
      <div class="apointment">
        <i class="iconfont icon-yuyuedan" style="color: #fff;font-size: 20px;font-weight: 400;"></i>
        <p style="color: #fff;line-height: 1px;padding: 0 10px;font-size: 12px;">预约服务</p>
        <div class="pointStyle">
          <div style="margin-right: 30px;margin-bottom: 20px;height: 100%;">
            <h1 style="color: #262626;margin-right: -32px;line-height: 10px;margin-top: 70px;">- 预约服务 -</h1>
            <h2 style="margin-right: -34px;font-size: 12px;color: #858c99;line-height: 1px;margin-bottom: 20px;">1对1专人服务，免费定制方案</h2>
            <div style="text-align: center;margin-left: 30px;;">
            <div style="padding: 0;">
              <span>联系人</span><input type="text" style="width: 96px;" placeholder="请输入姓名" class="boxSty">
              <div class="lineStyle"></div>
            </div>
            <div style="padding: 0;">
              <span>联系电话</span><input type="text" style="width: 96px;" placeholder="请输入电话" class="boxSty" >
              <div class="lineStyle"></div>
            </div>
            <div>
            <div style="padding: 0;">
              <span>验证码</span><input type="text" style="width: 96px;" placeholder="请输入验证码" class="boxSty"><el-button type="text">发生验证码</el-button></div>
              <div class="lineStyle"></div>
            </div>
            <div style="text-align: center;">
            <el-button type="danger" style="width: 100px;background-color: #e4645f;">提交</el-button>
            </div>
            </div>
            <!-- <div style="width: 230px;height: 230px;">
              <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="姓名:" prop="pass">
                  <el-input class="weiStyle"  v-model="ruleForm.pass" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="联系方式:" prop="checkPass">
                  <el-input class="phoneSty" v-model="ruleForm.checkPass" placeholder="请输入联系电话"></el-input>
                </el-form-item>
                <el-form-item label="验证码:" prop="age">
                  <div style="display: flex;">
                      <el-input class="yan" v-model.number="ruleForm.age" placeholder="请输入验证码" ></el-input><el-button type="text">发送验证码</el-button>
                  </div>
                </el-form-item>
                <el-form-item  style="width: 100%;text-align: center;">
                  <el-button type="danger" @click="submitForm('ruleForm')" style="width: 100px;background-color: #e4645f;margin-left: 5%;">提交</el-button>
                </el-form-item>
              </el-form>
            </div> -->
          </div>
        </div>
      </div>
      <!-- 电话咨询 -->
      <div class="phone">
        <i class="iconfont icon-24gl-phoneLoudspeaker" style="color: #fff;font-size: 20px;font-weight: 400;"></i>
        <p style="color: #fff;line-height: 1px;padding: 0 10px;font-size: 12px;">电话咨询</p>
        <div  class="teleSty">
          <img src="@/assets/电话号码.jpg" alt="" style="width: 160px;height: 50px;">
        </div>
      </div>
      <!-- 微信咨询 -->
      <div class="wechatSty">
        <i class="iconfont icon-weixin2" style="color: #fff;font-size: 20px;font-weight: 400;"></i>
        <p style="color: #fff;line-height: 1px;padding: 0 10px;font-size: 12px;">微信咨询</p>
        <div  class="weiSty">
          <img src="@/assets/微信.png" alt="" style="width: 222px;">
        </div>
      </div>
    </div>
    <!-- 滚动通知 -->
    <!-- <div class="info">
      <div class="text">
        <img src="@/assets/home-logo1@2x.png" alt="" style="height: 81px;">
        <img src="@/assets/home-logo2@2x.png" alt="" style="height: 81px;">
        <img src="@/assets/home-logo3@2x.png" alt="" style="height: 81px;">
        <img src="@/assets/home-logo4@2x.png" alt="" style="height: 81px;">
      </div>
    </div> -->
    <!-- 页尾 -->
    <!-- Scroll down to see the bottom-right button. -->
    <!-- <el-backtop target=".page-component__scroll .el-scrollbar__wrap"></el-backtop> -->
    <FootView></FootView>
  </div>
</template>

<script>
import FootView from '@/components/FootView.vue'
import HeadView from '@/components/HeadView.vue'
export default {
  components: {
    FootView,
    HeadView
  },
  data () {
    const checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入验证码'))
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'))
        }
      }, 1000)
    }
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入姓名'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入联系方式'))
      } else {
        callback()
      }
    }
    return {
      activeIndex: '1',
      bannerHeight: 700,
      screenWidth: 1920,
      ruleForm: {
        pass: '',
        checkPass: '',
        age: ''
      },
      rules: {
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        age: [
          { validator: checkAge, trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    this.move()
    this.setSize1()
    const that = this
    // 监听浏览器窗口大小改变
    window.addEventListener('resize', function () {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      that.screenWidth = width
      that.setSize()
    }, false)
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  },
  setSize1: function () {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    this.screenWidth = width
    // 图片                高 / 宽  700 / 1920
    this.bannerHeight = 700 / 1920 * this.screenWidth - 50
    document.getElementById('el-carousel').style.height = this.bannerHeight + 'px'
  },

  setSize: function () {
    this.bannerHeight = 700 / 1920 * this.screenWidth - 50
    document.getElementById('el-carousel').style.height = this.bannerHeight + 'px'
  },

  handleSelect (key, keyPath) {
    console.log(key, keyPath)
  },
  learnMore () {
    this.$router.push({
      // path: 'https://admin.yibaitec.com/'
    })
  },
  goMore () {
    this.$router.push({
      path: '/collaborationProcess'
    })
  },
  move (zoumadeng) {
    const info = document.querySelector('.info') // 容器元素
    const textNode = document.querySelector('.info > .text') // 文字元素
    textNode.innerHTML = textNode.innerHTML + zoumadeng
    // container.appendChild(textNode.cloneNode(true)); // 复制元素到后方
    const textWidth = textNode.offsetWidth // 获取文字元素宽度
    let count = info.offsetWidth // 初始化向左偏移为容器大小
    const loop = () => {
      if (count <= -textWidth) { // 如果文字偏移超出一个文字元素的宽度则复原
        textNode.style['margin-left'] = 0
        count = info.offsetWidth
      }
      textNode.style['margin-left'] = `${count--}px` // 继续向左移动
      window.requestAnimationFrame(() => loop()) // 动画递归调用
    }
    window.requestAnimationFrame(() => loop()) // 启动动画
  }
}

</script>

<style lang="less">
::v-deep  .element.style{
  padding: 0 !important;
}

.left-mask{
  height: 354px;
  width: 300px;
  position: absolute;
  left: 0;
  top: 36%;
  z-index: 1;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
}
@keyframes marquee {
0% {
transform: translateX(0);
}
100% {
transform: translateX(-100%);
}
}
.phoneSty .el-input__inner {
  width: 200px;
}
.weiStyle .el-input__inner {
  width: 200px;
}
.marquee {
display: block;
white-space: nowrap;
overflow: hidden;
animation: marquee 10s linear infinite;
}
.topSty{
  margin-top: 15px;
  // padding: 0;
  margin-left: 16px;
  // margin-left: 0;
  // margin-right: 0;
}
.cardSty{
  text-align: center;
  margin-left: 25px;
  // margin-right: 2px;
}
.apointment{
  line-height: 200%;
  border-bottom:1px solid #595959;
  height: 33%;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
.apointment:hover .pointStyle{
      display: block;
 }
.phone{
  line-height: 200%;
  border-bottom:1px solid #595959;
  height: 33%;
}
.phone:hover .teleSty{
      display: block;
 }
.wechatSty{
  line-height: 200%;
  border-bottom:1px solid #595959;
  height: 33%;
}
.wechatSty:hover .weiSty{
      display: block;
 }
.boxSty{
  border: none;
  font-size: 14px;
  width: 20em;
  margin-left: 5%;
  color: #676e79;
  outline: 0;
}

.lineStyle{
  border-bottom: 1.5px dashed #858c99;
  margin-top: 10px;
  margin-bottom: 20px;
}
.pointStyle{
  text-align: center;
  position: fixed;
  width: 30%;
  right: 76px;
  top: 46%;
  z-index: 1 !important;
  background-image: url('@/assets/form-box-bg.png');
  background-size: 100% 101%;
  display: none;
}
.logoAni:hover{
  animation: zoomOutLeft;
  animation-duration: 3s;
}
.logoAni1:hover{
  animation: zoomOutLeft;
  animation-duration: 3s;
}
.logoAni2:hover{
  animation: zoomOutLeft;
  animation-duration: 3s;
}
.logoAni3:hover{
  animation: zoomOutLeft;
  animation-duration: 3s;
}
.el-card__body{
  padding: 0 !important;
  // border: 0.2px solid rgba(194, 194, 233, 0.5);
}
.el-carousel__item:nth-child(2n){
  background-color: #fff !important;
}
.info{
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  height:350px;
  // line-height: 30px;
  padding-left: .5rem;
  margin-bottom: 5px;
  border: 1px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  // background-image: linear-gradient(to right, #082e5d, #001531),linear-gradient(90deg, #3579CB, #011532);
  font-size: calc(100vw * 12 / 1920);
  width:100%;
  color: #fff;
  ::v-deep span{
    color: #7CB8FE;
    font-weight: 600;
    //padding:0 5px;
  }
}
.advantageSty{
  width: 25%;
  background-image: linear-gradient(#c1e8f0, #ebecf6);
  height:320px;
  margin:0 20px;
  transition: 0.3s;
}
// .shadow-drop-2-bl:hover {
//   animation: fadeIn;
//   animation-duration: 2s;
// }
.borderNone /deep/ .el-input__inner {
  border: none;
  text-align: left;
  margin-left: 20px;
}
.yan .el-input__inner {
  width: 130px;
  margin-right: 10px;
}
.advantageSty:hover{
  animation: pulse;
  animation-duration: 2s;
}
.advantageSty1{
  width: 25%;
  background-image: linear-gradient(#fdadad, #f3f3f3);
  height:320px;
  margin:0 20px;
  transition: 0.3s;
}
.advantageSty1:hover{
  animation: pulse;
  animation-duration: 2s;
}
.advantageSty2{
  width: 25%;
  background-image: linear-gradient(#d7b5db, #d9f6f6);
  height:320px;
  margin:0 20px;
  transition: 0.3s;
}
.advantageSty2:hover{
  animation: pulse;
  animation-duration: 2s;
}
.advantageSty3{
  width: 25%;
  background-image: linear-gradient(#adf3d6, #e7fbeb);
  height:320px;
  margin:0 20px;
  transition: 0.3s;
}
.advantageSty3:hover{
  animation: pulse;
  animation-duration: 2s;
}
.right-mask{
  height: 354px;
  width: 300px;
  position: absolute;
  right: 0;
  top: 36%;
  z-index: 1;
  // background-color: red;
  background: linear-gradient(-270deg, rgba(255, 255, 255, 0) 0%, #fff 100%);

}
.el-carousel__item:nth-child(2n+1){
  background-color: #fff !important;
}

.footContainer{
  position: relative;
}
.teleSty{
  position: fixed;
  right: 80px;
  top: 51%;
  z-index: 1;
  display: none;
}
.teleSty:hover{
  display: block;
}
.weiSty{
  position: fixed;
  right: 80px;
  top: 54%;
  z-index: 1;
  display: none;
}

.weiSty:hover{
  display: block;
}

@keyframes move{
  0%{
    transform: translate3d(-6%, 0, 0 );
  }
  100%{
    transform: translateZ(0);
  }
}
.el-menu.el-menu--horizontal {
  border-bottom: none !important
}

// 轮播图样式
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.conentStyle{
  margin-right:110px;
  // height: 100%;
  display: flex;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.footStyle{
  width: 100%;
  min-width: 1200px;
  height: 308px;
  background-color: #151b1e;
}
.footDetail{
  color: #938b8b;
  font-size: 12px;
  line-height: 40px;
}
.footDetail1{
  color: #938b8b;
  font-size: 12px;
  line-height: 10px;
  padding: 10px;
}
// 布局
.el-header,
.el-footer {
  background-color: white;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-aside {
  background-color: white;
  color: #333;
}

.el-main {
  background-color: white;
  color: #333;
  text-align: left;
  line-height: 30px;
  margin-left: 118px;
  margin-top: 80px;
}
.el-carousel__arrow{
  top: 50% !important;
}
.news .el-carousel__arrow{
  top: 50% !important;
}

body>.el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
.scroll_box {
        /* 不想看到超出盒子范围的图片，因此需要overflow：hidden；进行隐藏 */
        overflow: hidden;
        width: 100%;
        // height: 340px;
        // margin-top:100px;
    }

    .scroll_box ul{
        display: flex;
        margin-top: 10px;
        width: 5280px;
        // height: 320px;
        animation: scroll 60s infinite linear;
    }
    el-carousel__container {
        height: 100% !important;
      }
    .scroll_box ul li {
        position: relative;
        display: flex;
        // justify-content: center;
        align-items: center;
        // height: 320px;
        width: 240px;
        list-style: none;
        // margin: 0 8px;
        border-radius: 15px;
    }

    .scroll_box ul li img{
        width: 180px;
    }
    /* 动画 */
    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-3840px);
        }
      }

</style>
<style>
  /* .el-carousel__container {
        height: 100% !important;
      }

    img {
        display: inline-block;
        height: auto;
        max-width: 100%;
      } */
</style>
